// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-agenda-tsx": () => import("./../../../src/pages/agenda.tsx" /* webpackChunkName: "component---src-pages-agenda-tsx" */),
  "component---src-pages-flitsen-tsx": () => import("./../../../src/pages/flitsen.tsx" /* webpackChunkName: "component---src-pages-flitsen-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-posts-tsx": () => import("./../../../src/pages/posts.tsx" /* webpackChunkName: "component---src-pages-posts-tsx" */),
  "component---src-pages-repetities-tsx": () => import("./../../../src/pages/repetities.tsx" /* webpackChunkName: "component---src-pages-repetities-tsx" */),
  "component---src-pages-shop-tsx": () => import("./../../../src/pages/shop.tsx" /* webpackChunkName: "component---src-pages-shop-tsx" */),
  "component---src-pages-vacatures-tsx": () => import("./../../../src/pages/vacatures.tsx" /* webpackChunkName: "component---src-pages-vacatures-tsx" */),
  "component---src-templates-archive-tsx": () => import("./../../../src/templates/archive.tsx" /* webpackChunkName: "component---src-templates-archive-tsx" */),
  "component---src-templates-category-tsx": () => import("./../../../src/templates/category.tsx" /* webpackChunkName: "component---src-templates-category-tsx" */),
  "component---src-templates-flitsen-tsx": () => import("./../../../src/templates/flitsen.tsx" /* webpackChunkName: "component---src-templates-flitsen-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-templates-post-tsx": () => import("./../../../src/templates/post.tsx" /* webpackChunkName: "component---src-templates-post-tsx" */)
}

